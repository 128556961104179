<template lang="pug">
Modal(size="lg" name="video_popup" title="таким будет ваше <br>поздравление" @closeModal="closeModal")
  template(v-slot:content)
    .modal__video
      .modal__video-preview(@click="play()" :class="{ hide: playing }")
        img(src="../../assets/images/preview.svg")
      video(
        id="video"
        controls
        src="../../assets/video/example.mp4"
      )
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      playing: false,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {
      setTimeout(() => {
        // this.play();
      }, 1000);
    },
    pause() {
      let videoElement = document.getElementById("video");
      videoElement.pause();
      this.playing = false;
    },
    play() {
      let videoElement = document.getElementById("video");
      this.playing = true;
      setTimeout(() => {
        videoElement.play();
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.video {
  &-back {
    position: absolute;
    top: rem(25px);
    left: rem(21px);
    display: flex;
    align-items: center;
    margin-bottom: rem(17px);
    text-transform: uppercase;
    font-size: rem(10px);
    color: #fff;
    z-index: 1;
    img {
      width: rem(5px);
      margin-right: rem(9px);
    }
  }
  &-trigger {
    position: absolute;
    top: rem(25px);
    right: rem(21px);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: rem(10px);
    color: #fff;
    z-index: 1;
    span {
      margin-right: rem(10px);
    }
  }
}
#video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
