<template>
  <modal
    :name="name"
    height="auto"
    :adaptive="true"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div
      class="modal"
      :class="[
        'modal-' + name,
        size ? 'modal--' + size : '',
        success ? 'modal-success' : '',
      ]"
    >
      <div class="modal__content">
        <div class="modal__close" @click="closeModal(name)"></div>
        <div class="modal__header">
          <h2
            v-if="title"
            class="modal__title"
            :class="success ? 'modal-success__title' : ''"
            v-html="title"
          ></h2>
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    success: {
      type: Boolean,
    },
  },
  methods: {
    closeModal(name) {
      this.$emit("closeModal", name);
    },
    beforeOpen(event) {
      this.$parent.beforeOpen(event);
    },
  },
  mounted() {
    // this.$modal.show("success_modal");
  },
};
</script>

<style lang="scss">
.vm--container {
  overflow: auto;
  z-index: 999999 !important;
  .vm--overlay {
    background: rgba(84, 21, 51, 0.9);
  }
}
.vm--modal {
  height: auto !important;
  padding-bottom: 15vh;
  justify-content: center;
  overflow: visible !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  background-color: transparent !important;

  @media (min-width: $md) {
    padding: 0;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  // position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  // max-width: 88%;
  margin-top: 10vh;
  padding: rem(65px) rem(31px) rem(60px);
  background: #cc085a;
  border-radius: rem(10px);
  color: #fff;
  overflow: hidden;
  &-check_photo {
    min-height: auto;
  }
  &-reminder__popup {
    padding-top: rem(65px);
    padding-bottom: rem(60px);
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(10px);
    right: rem(10px);
    width: rem(43px);
    height: rem(43px);
    background-image: url("../assets/images/icons/close.svg");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
    transition: 0.4s;
    z-index: 1;
  }
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__content {
    position: static;
    z-index: 1;
  }
  &__title {
    margin-bottom: rem(30px);
    font-size: rem(24px);
    line-height: rem(30px);
    text-transform: uppercase;
    background: linear-gradient(
        258.95deg,
        #e2b758 17.32%,
        #fff5e0 50.82%,
        #e2b758 72.69%
      ),
      #e2b758;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: "Demi";
    text-align: center;
    & + .modal__text {
      margin-top: rem(-7px);
    }
  }
  &__subtitle {
    position: relative;
    margin-bottom: rem(27px);
    font-size: rem(16px);
    color: #fff;
    text-align: center;
    z-index: 1;
  }
  &__text {
    margin-top: rem(0px);
    margin-bottom: rem(16px);
    text-align: center;
    font-size: rem(16px);
    line-height: rem(19px);
  }
  &-signup {
    width: rem(758px);
  }
  &-video_popup {
    max-width: 100vw;
    @include size(100vw, 100vh);
    margin-top: 0;
    padding: 0 !important;
    background: none;
    overflow: visible;
    .modal__header {
      margin: auto auto 0;
    }
    .modal__title {
      display: inline-block;
      margin: 0 auto rem(30px);
    }
    .modal__video {
      position: relative;
      max-width: 90%;
      height: 70vh;
      margin: auto;
      border-radius: 10px;
      overflow: hidden;
      &-preview {
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cc085a;
        transition: 0.4s;
        cursor: pointer;
        z-index: 10;
        img {
          width: rem(60px);
          transition: 0.4s;
          &:hover {
            transform: scale(1.1);
          }
        }
        &.hide {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
  &__another-option {
    margin: rem(15px) 0 rem(27px);
    font-size: rem(14px);
    line-height: rem(17px);
    text-align: center;
    a {
      color: $pink;
      border-bottom: 1px solid currentColor;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__list {
    width: 100%;
    margin: auto;
    &-item {
      position: relative;
      padding-left: rem(33px);
      margin-bottom: rem(20px);
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: rem(8px);
        width: rem(8px);
        height: rem(8px);
        border-radius: 50%;
        background: $yellow;
      }
    }
  }
  &-success {
    &__title {
      position: relative;
      margin-bottom: rem(5px) !important;
    }
  }
  .success_modal {
    &__text {
      margin-top: 0;
    }
    &-back {
      font-size: rem(12px);
      color: #afafaf;
      text-align: center;
    }
    &-image {
      margin: auto;
    }
  }

  @media (min-width: $sm) {
    min-height: auto;
    justify-content: flex-start;
    padding: rem(50px) rem(85px) rem(60px);
    &--md {
      width: rem(600px);
      padding: rem(70px) rem(25px) rem(46px);
    }
    &--lg {
      width: rem(834px);
      padding: rem(40px) rem(25px) rem(46px);
    }
    &-reminder__popup {
      padding-top: rem(98px);
      padding-bottom: rem(105px);
    }
    &__title {
      margin-bottom: rem(25px);
      font-size: rem(36px);
      line-height: rem(40px);
    }
    &__text {
      margin-top: rem(27px);
    }
    &__list {
      width: 70%;
    }
    &-video_popup {
      width: 100vw;
      video {
        width: auto;
        background: none;
        object-fit: fill;
      }
      .modal__content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .modal__title {
        margin-top: auto;
      }
      .modal__video {
        max-width: 20%;
        margin: 0 auto auto;
      }
    }
    &__close {
      top: rem(25px);
      right: rem(25px);
      @include size(rem(30px));
      .modal-test_popup & {
        top: rem(10px);
        right: rem(10px);
      }
    }
    &__subtitle {
      margin-bottom: 0;
      font-size: rem(24px);
    }
    &-success {
      padding: rem(80px) rem(123px);
    }
  }
}
</style>
