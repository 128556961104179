import { render, staticRenderFns } from "./AppExample.vue?vue&type=template&id=10aff7de&scoped=true&lang=pug&"
import script from "./AppExample.vue?vue&type=script&lang=js&"
export * from "./AppExample.vue?vue&type=script&lang=js&"
import style0 from "./AppExample.vue?vue&type=style&index=0&id=10aff7de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10aff7de",
  null
  
)

export default component.exports