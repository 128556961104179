<template lang="pug">
section.hero.section#home
  .container
    .hero__content
      h1.hero__title 
        span.gradient-text ПОЗДРАВЬТЕ ТЕХ, <br>КТО ВАЖЕН, <br>СЛОВАМИ ВЕЛИКИХ
        small.gradient-text.hidden-xs И выигрывайте <br> призы
      AppExample.visible-xs
      img.hero__img.visible-xs(
        src="../assets/images/hero/hero.png"
        srcset="../assets/images/hero/hero.png, ../assets/images/hero/hero@2x.png 2x"
        alt=""
      )
      .hero-wtb
        router-link(:to="{ path: '/', hash: '#where-to-buy'}").hero-wtb__link.btn.btn--bordered
          img(src="../assets/images/icons/location.svg")
          span Где купить?
        router-link.hero-wtb__edadil(:to="{ path: '/', hash: '#edadil'}")
          img(src="../assets/images/edadil_logo.png" alt="")
          span Акция в Едадил
            small Вход и регистрация
      .visible-xs
        .hero__title 
          span.gradient-text И ПОЛУЧите <br>ПРИЗЫ
        img.hero__present(
          src="../assets/images/hero/hero-1-mobile.png"
          srcset="../assets/images/hero/hero-1-mobile.png,../assets/images/hero/hero-1-mobile@2x.png 2x"
          alt=""
        )
        .hero__nav
          router-link.btn.btn--primary(:to="{ path: '/', hash: '#create'}")
            span Как создать поздравление?
            svg(width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(d="M24.0607 13.0607C24.6464 12.4749 24.6464 11.5251 24.0607 10.9393L14.5147 1.3934C13.9289 0.80761 12.9792 0.80761 12.3934 1.3934C11.8076 1.97918 11.8076 2.92893 12.3934 3.51472L20.8787 12L12.3934 20.4853C11.8076 21.0711 11.8076 22.0208 12.3934 22.6066C12.9792 23.1924 13.9289 23.1924 14.5147 22.6066L24.0607 13.0607ZM1.31134e-07 13.5L23 13.5L23 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z" fill="#9E0D53")
          router-link.btn.btn--primary(:to="{ path: '/', hash: '#prizes'}")
            span Получить призы
            svg(width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(d="M24.0607 13.0607C24.6464 12.4749 24.6464 11.5251 24.0607 10.9393L14.5147 1.3934C13.9289 0.80761 12.9792 0.80761 12.3934 1.3934C11.8076 1.97918 11.8076 2.92893 12.3934 3.51472L20.8787 12L12.3934 20.4853C11.8076 21.0711 11.8076 22.0208 12.3934 22.6066C12.9792 23.1924 13.9289 23.1924 14.5147 22.6066L24.0607 13.0607ZM1.31134e-07 13.5L23 13.5L23 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z" fill="#9E0D53")
          router-link.btn.btn--primary(:to="{ path: '/', hash: '#test'}")
            span Узнать, кто вы из писателей
            svg(width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(d="M24.0607 13.0607C24.6464 12.4749 24.6464 11.5251 24.0607 10.9393L14.5147 1.3934C13.9289 0.80761 12.9792 0.80761 12.3934 1.3934C11.8076 1.97918 11.8076 2.92893 12.3934 3.51472L20.8787 12L12.3934 20.4853C11.8076 21.0711 11.8076 22.0208 12.3934 22.6066C12.9792 23.1924 13.9289 23.1924 14.5147 22.6066L24.0607 13.0607ZM1.31134e-07 13.5L23 13.5L23 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z" fill="#9E0D53")

  img.hero__img.hidden-xs(
    src="../assets/images/hero/hero.png"
    srcset="../assets/images/hero/hero.png,../assets/images/hero/hero@2x.png 2x"
    alt=""
  )
  
  AppExample.hidden-xs
</template>

<script>
import AppExample from "./AppExample.vue";
export default {
  components: {
    AppExample,
  },
  data: () => ({}),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    click() {
      this.$emit("update", true);
    },
  },
  mounted() {
    // this.toCheckUpload()
    this.showModal("receipt_question");
  },
};
</script>
<style lang="scss" scoped>
.hero {
  // height: 100vh;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 100;
  .container {
    position: relative;
    height: 100%;
    z-index: 3;
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 14vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 3;
  }
  &__title {
    width: 100%;
    margin-bottom: rem(20px);
    font-size: rem(30px);
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
    text-shadow: -14.835px 20.769px 22.2526px rgba(99, 19, 19, 0.58);
    color: #fff;
    span {
      display: block;
    }
    small {
      display: block;
      font-size: rem(14px);
      text-transform: none;
      text-shadow: none;
    }
  }
  &__img {
    width: 150%;
    max-width: none;
    margin-bottom: rem(-30px);
  }
  &__nav {
    padding-bottom: rem(45px);
    .btn {
      width: 100%;
      height: rem(88px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(5px) rem(30px);
      margin-bottom: rem(20px);
      line-height: rem(22px);
      font-size: rem(20px);
      letter-spacing: normal;
      text-align: left;
      svg {
        width: rem(23px);
        flex-shrink: 0;
      }
    }
  }
  &-wtb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;
    &__img {
      max-height: 60vh;
      object-fit: contain;
    }
    &__link {
      width: rem(241px);
      height: rem(50px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem(28px);
      padding: rem(7px) rem(10px) rem(7px) rem(7px);
      text-transform: none;
      z-index: 1;
      font-size: rem(18px);
      line-height: 1;
      img {
        @include size(auto, 100%);
        margin-right: rem(12px);
      }
    }
    &__edadil {
      width: rem(241px);
      height: rem(50px);
      margin-bottom: rem(60px);
      display: flex;
      align-items: center;
      padding: rem(5px) rem(10px) rem(5px) rem(7px);
      background: #fff;
      color: #000;
      box-shadow: 0px 5px 10px rgba(99, 19, 19, 0.58);
      * {
        flex-shrink: 0;
        flex-grow: 0;
      }
      img {
        @include size(auto, 100%);
        margin-right: rem(25px);
        flex-shrink: 0;
        flex-grow: 0;
      }
      span {
        margin-right: rem(5px);
        font-size: rem(16px);
        line-height: 1.2;
        white-space: nowrap;
      }
      small {
        display: block;
        font-size: rem(12px);
        opacity: 0.5;
      }
    }
  }
  &__text {
    margin: rem(10px) auto;
    font-size: rem(16px);
    text-align: center;
    text-transform: uppercase;
    line-height: rem(19px);
    color: #fff;
  }
  @media (min-width: $sm) {
    &__title {
      font-size: rem(45px);
      line-height: rem(56px);
      margin-bottom: rem(22px);
    }

    &__text {
      width: 80%;
    }
  }
  @media (min-width: $md) {
    height: 100vh;
    background: url("../assets/images/hero/background-desktop.jpg") no-repeat
      center;
    background-size: cover;
    &__content {
      padding-top: 22.8vh;
      align-items: flex-start;
    }
    &__title {
      // width: 58%;
      margin-bottom: rem(28px);
      font-size: 8.1vh;
      line-height: 9vh;
      text-align: left;
      letter-spacing: 0.25vh;
      small {
        display: block;
        margin-top: rem(10px);
        font-size: 6.2vh;
        line-height: 6.8vh;
        letter-spacing: normal;
        font-family: "Avenir-Light";
        text-transform: uppercase;
      }
    }
    &__date {
      margin-bottom: 0;
      font-size: rem(24px);
    }
    &__text {
      width: 100%;
      margin: 0;
      text-align: left;
      font-size: rem(24px);
      line-height: rem(29px);
    }
    &__img {
      position: absolute;
      width: 80vw;
      max-width: 100%;
      max-height: 90vh;
      top: 16vh;
      left: 33%;
      margin-bottom: 0;
      object-fit: contain;
    }
    &-wtb {
      &__img {
        max-width: 100%;
        height: 100%;
      }
      &__edadil {
        width: rem(329px);
        height: rem(68px);
        margin-bottom: 0px;
        img {
          @include size(auto, 80%);
          margin-right: rem(38px);
        }
      }
      &__link {
        width: rem(329px);
        height: rem(68px);
        margin-bottom: rem(14px);
        img {
          height: 80%;
          margin-right: rem(17px);
        }
      }
    }
  }
}
</style>
