var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',[_c('inputText',{class:{ error: _vm.validationStatus(_vm.$v.name) || this.errorMessage.name },attrs:{"placeholder":"Ваше имя","field":_vm.email,"error":(!_vm.$v.name.required && _vm.$v.name.$error
          ? 'Обязательное поле'
          : !_vm.$v.name.rus && _vm.$v.name.$error
          ? 'Введите текст на русском языке'
          : '') || this.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
      },attrs:{"placeholder":"Ваш E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('textArea',{class:{
        error: _vm.validationStatus(_vm.$v.message) || this.errorMessage.message,
      },attrs:{"placeholder":"Текст обращения","field":_vm.message,"error":(!_vm.$v.message.required && _vm.$v.message.$error
          ? 'Обязательное поле'
          : '') || this.errorMessage.message},on:{"input":function($event){_vm.message = $event}}}),_vm._v(" "),_c('File',{attrs:{"chosenFileName":_vm.chosenFileName},on:{"submitMedia":_vm.submitMedia,"deleteFile":_vm.deleteFile}}),_vm._m(0),_c('div',{staticClass:"feedback__checkbox_list"},[_c('checkBox',{class:{
          error: _vm.validationStatus(_vm.$v.checkbox) || this.errorMessage.checkbox,
        },attrs:{"label":"Мне исполнилось 18 лет.","field":_vm.checkbox,"error":this.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}})],1),_c('div',{staticClass:"feedback__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback__text"},[_vm._v(" Персональные данные, отправленные через эту форму, будут использованы только для ответа на конкретный вопрос, а также в целях аналитики, и не будут использоватьсяв маркетинговых целях в соответствии с "),_c('a',{staticClass:"gradient-text",attrs:{"href":"https://www.mars.com/privacy-policy-russia","target":"_blank"}},[_vm._v("Положением о конфиденциальности")]),_vm._v(". ")])
}]

export { render, staticRenderFns }