<template lang="pug">
section.where-to-buy.section#where-to-buy
  .container
    .section__title.gradient-text(data-show) Где купить?
    .where-to-buy-list(data-show)
      a.where-to-buy-list__item(
        v-for="(item, i) in partners"
        :key="i"
        :href="item.link"
        target="_blank"
        :style="item.background ? 'background:' + item.background : ''"
      )
        img(:src="require(`../assets/images/partners/${item.logo}`)" alt="")
        .where-to-buy-list__item-front
          //- span Перейти в магазин
          //- svg(
          //-   width="20"
          //-   height="17"
          //-   viewBox="0 0 20 17"
          //-   fill="none"
          //-   xmlns="http://www.w3.org/2000/svg"
          //- )
          //-   path(
          //-     d="M11.8 1L10.54 2.3125L15.58 7.5625H1V9.4375H15.58L10.54 14.6875L11.8 16L19 8.5L11.8 1Z"
          //-     fill="#CFAC72"
          //-     stroke="#CFAC72"
          //-     stroke-width="0.8"
          //-   )
  AppFooter
</template>

<script>
import AppFooter from "./AppFooter.vue";
export default {
  components: { AppFooter },
  data: () => ({
    partners: [
      {
        logo: "ozon.svg",
        background: "#0069FF",
        link: "https://www.ozon.ru/highlight/korkunov-814044/",
      },
      {
        logo: "vprok.jpg",
        background: "#1E8737",
        link: "https://www.perekrestok.ru/cat/b/13481/korkunov",
      },
      {
        logo: "sber.jpg",
        background: "#21A038",
        link: "https://sbermarket.ru/marketing/korkunov",
      },
      {
        logo: "ya_market.svg",
        link: "https://market.yandex.ru/special/mars_len",
      },
      {
        logo: "lenta.svg",
        link: "https://lenta.com/brand/korkunov/",
        background: "#174D9E",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.where-to-buy {
  padding-top: rem(50px);
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  .section__title {
    text-shadow: -7.88611px 11.0406px 11.8292px rgba(99, 19, 19, 0.58);
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: "";
      flex: 1 1 auto;
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 calc(50% - #{rem(10px)});
      margin: 0 rem(5px) rem(10px);
      background: #fff;
      box-shadow: rem(8px) rem(8px) 0px rgba(0, 0, 0, 0.25);
      border-radius: rem(5px);
      transition: 0.4s;
      overflow: hidden;
      &::after {
        content: "";
        padding-bottom: 45%;
      }
      &:hover {
        box-shadow: none;
      }

      img {
        max-width: 70%;
        max-height: 80%;
      }
      &-front {
        position: absolute;
        bottom: rem(28px);
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $yellow;
        font-size: rem(20px);
        text-transform: uppercase;
        transition: 0.2s;
        opacity: 0;
        svg {
          display: block;
          margin-left: rem(7px);
        }
      }
      &:hover {
        .where-to-buy-list__item-front {
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: $sm) {
    &-list {
      &__item {
        flex: 0 0 calc(50% - #{rem(16px)});
      }
    }
  }
  @media (min-width: $md) {
    padding-top: rem(45px);
    background: url("../assets/images/prizes/background.jpg") no-repeat center
      bottom;
    background-size: cover;
    .section__title {
      font-size: rem(58px);
      margin-bottom: rem(50px);
    }
    &-list {
      width: calc(100% + rem(20px));
      margin: 0 rem(-10px) rem(35px);
      &__item {
        margin: 0 rem(10px) rem(25px);
        flex: 0 0 calc(20% - #{rem(20px)});
        &::after {
          content: "";
          padding-bottom: 50%;
        }
      }
    }
  }
}
</style>
