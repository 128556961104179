<template lang="pug">
.example
  .example__opener(@click="$modal.show('video_popup')")
    svg.hidden-xs(
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    )
      circle(
        cx="19.5719"
        cy="19.1227"
        r="17.6735"
        stroke="#9E0D53"
        stroke-width="2"
      )
      path(
        d="M30.7754 19.1221L13.9693 28.8251L13.9693 9.41903L30.7754 19.1221Z"
        fill="#9E0D53"
      )
    span Посмотреть пример 
      span.hidden-xs поздравления
</template>

<script>
export default {
  data: () => ({
    showPopup: false,
    playing: false,
  }),
  methods: {
    setValue(value) {
      this.showPopup = value;
      console.log(value);
    },
  },
  created: function () {
    this.$parent.$on("update", this.setValue);
  },
};
</script>

<style lang="scss" scoped>
.example {
  margin-bottom: rem(33px);
  z-index: 10;
  &__opener {
    display: flex;
    padding: rem(12px) rem(30px) rem(12px) rem(24px);
    align-items: center;
    color: $red;
    font-size: rem(16px);
    line-height: rem(18px);
    background: linear-gradient(
        258.95deg,
        #e2b758 17.32%,
        #fff5e0 50.82%,
        #e2b758 72.69%
      ),
      #e2b758;
    box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.3);
    transition: 0.4s;
    pointer-events: all;
    cursor: pointer;
    z-index: 2;
    &:hover {
      transform: translateX(-10px);
    }
    svg {
      @include size(rem(37px));
      flex-shrink: 0;
      margin-right: rem(24px);
    }
  }

  @media (min-width: $md) {
    position: absolute;
    bottom: 14vh;
    right: 6vw;
    margin-bottom: 0;
    &__opener {
      display: flex;
      width: rem(300px);
    }
  }
}
</style>
