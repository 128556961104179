<template lang="pug">
section.steps.section#create(:class="{ active: $route.hash === `#create` }")
  .steps__title.section__title(data-show)
    span Как создать поздравление?
  .visible-xs
    VueSlickCarousel.steps-nav.steps-nav-mobile(
      ref="c2"
      :asNavFor="c1"
      :slidesToShow="1"
      :arrows="false"
      :centerMode="true"
      centerPadding="80px"
      :infinite="false"
      :swipe="false"
    )
      .steps-nav__item-wrapper(v-for="(step, i) in steps" data-show :key="i" @click="showNext(i)")
        .steps-nav__item
          .steps-nav__item-index.gradient-text {{ i+1 }}
          .steps-nav__item-col
            .steps-nav__item-descr {{ step.text | typograf }}
        
    VueSlickCarousel.steps-detail.steps-detail-mobile(
      data-show
      ref="c1"
      :asNavFor="c2"
      :arrows="false"
      :focusOnSelect="true"
      :infinite="false"
      @afterChange="afterChange"
      :swipe="false"
    )
      .steps-detail__item(v-for="(item, index) in 4" :key="index")
        video.video( 
          :id="`video-${item - 1}`" 
          autobuffer
          alt="altText" 
          :controls="false" 
          muted="muted"
          defaultMuted
          playsinline
          preload="none"
          oncontextmenu="return false;"
          :src="item === 1 ? require(`../assets/video/video-${item - 1}.mp4`) : ''" :data-path="require(`../assets/video/video-${item - 1}.mp4`)"
        )
      //- template(#nextArrow)
      //-   .slick-next(@click="showNext(i)")
      //- template(#prevArrow)
      //-   .slick-prev(@click="showNext(i)")
  router-link.steps__btn.btn.btn--secondary.visible-xs(:to="{ path: '/', hash: '#where-to-buy'}")
    img(src="../assets/images/icons/location.svg")
    span.gradient-text Где купить?
  .container
    .steps__content.hidden-xs
      .steps-nav(data-show)
        .steps-nav__item-wrapper(v-for="(step, i) in steps" :key="i" @click="showNextDesktop(i)" :class="{ _active: activeStepDesk == i }" data-show)
          .steps-nav__item
            .steps-nav__item-index.gradient-text {{ i+1 }}
            .steps-nav__item-col
              .steps-nav__item-descr {{ step.text | typograf }}
              .steps-nav__item-hidden
                router-link( v-if="i === 0" :to="{ path: '', hash: '#where-to-buy'}").steps-nav__item-button.btn.btn--primary(href="#where-to-buy") Где купить?

      .steps-detail.steps-detail-desktop.hidden-xs(data-show)
        .steps-detail__item(v-for="(item, index) in 4" :key="index")
          video.video( :id="`video-desktop-${item - 1}`" src="" :data-path="require(`../assets/video/video-${item - 1}-desktop.webm`)" alt="altText" :controls="false" muted)

      ul.steps-dots
        li.steps-dots__dot(
          v-for="i in 4"
          :key="i"
          :class="{ active: i === activeStepDesk + 1 }"
          @click="activeStepDesk = i - 1"
        )
</template>
<script>
import AppScrollDown from "./AppScrollDown.vue";
import VueSlickCarousel from "vue-slick-carousel";
import { isMobile } from "../utils/device";

export default {
  components: { AppScrollDown, VueSlickCarousel },
  props: ["videoFinish", "ker"],
  data: () => ({
    publicPath: process.env.BASE_URL,
    activeStep: 0,
    activeStepDesk: 0,
    c1: undefined,
    c2: undefined,
    videoEnded: true,
    steps: [
      {
        text: "Купите продукцию «Коркунов»",
      },
      {
        text: "Отсканируйте QR-код с открытки из упаковки",
      },
      {
        text: "Создайте поздравление словами великих",
      },
      {
        text: "Впишите ПИН-код в открытку и подарите тому, кто важен",
      },
    ],
    settings: {
      infinity: true,
      fade: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "10vw",
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "80px",
          },
        },
      ],
    },
    production: [
      {
        img: "product_1",
      },
      {
        img: "product_2",
      },
      {
        img: "product_3",
      },
      {
        img: "product_4",
      },
    ],
  }),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    play(id) {
      let win = document.getElementById(id).contentWindow;
      win.postMessage("start", "*");
    },
    afterChange(slideIndex) {
      this.activeStep = slideIndex;
    },
    showNext(videoId) {
      const $this = this;
      const video = document.querySelector(`#video-${videoId}`);
      const videoNext = document.querySelector(`#video-${$this.activeStep}`);

      if ($this.activeStep + 1 === this.steps.length) {
        $this.activeStep = this.steps.length - 1;
      }

      if (this.activeStep === 0) {
        this.activeStep = 0;
      }
      function goTo(dir) {
        $this.videoEnded = true;
        if (dir) {
          $this.$refs.c1.goTo($this.activeStep + 1);
        } else {
          $this.$refs.c1.goTo($this.activeStep - 1);
        }
      }
      if (this.activeStep > videoId) {
        if (video) {
          if (this.videoEnded) {
            goTo();
            video.play();
            this.videoEnded = false;
            video.addEventListener("ended", function end(e) {
              $this.videoEnded = true;
              console.log($this.videoEnded);
              video.removeEventListener("ended", end);
            });
          } else {
            videoNext.playbackRate = 6.0;
            videoNext.addEventListener("ended", function end(e) {
              console.log("The End");
              videoNext.playbackRate = 1.0;
              $this.videoEnded = true;
              videoNext.removeEventListener("ended", end);
              setTimeout(() => {
                goTo();
                console.log(
                  "to ",
                  document.querySelector(`#video-${$this.activeStep - 1}`)
                );
                let videoAfter = document.querySelector(
                  `#video-${$this.activeStep - 1}`
                );
                videoAfter.play();
                videoAfter.addEventListener("ended", function end(e) {
                  $this.videoEnded = true;
                  console.log($this.videoEnded);
                  videoAfter.removeEventListener("ended", end);
                });
                $this.videoEnded = false;
              }, 300);
            });
          }
        }
      } else {
        if (videoNext) {
          if (this.videoEnded) {
            goTo(true);
            video.play();
            this.videoEnded = false;
            video.addEventListener("ended", function end(e) {
              console.log($this.videoEnded);
              $this.videoEnded = true;
              video.removeEventListener("ended", end);
            });
          } else {
            videoNext.playbackRate = 6.0;
            videoNext.addEventListener("ended", function end(e) {
              console.log("The End");
              videoNext.playbackRate = 1.0;
              $this.videoEnded = true;
              videoNext.removeEventListener("ended", end);
              setTimeout(() => {
                goTo(true);
                console.log(
                  "to ",
                  document.querySelector(`#video-${$this.activeStep + 1}`)
                );
                let videoAfter = document.querySelector(
                  `#video-${$this.activeStep + 1}`
                );
                videoAfter.play();
                videoAfter.addEventListener("ended", function end(e) {
                  $this.videoEnded = true;
                  console.log($this.videoEnded);
                  videoAfter.removeEventListener("ended", end);
                });
                $this.videoEnded = false;
              }, 300);
            });
          }
        }
      }
    },
    showNextBySwipe(touchendX, touchstartX, touchendY, touchstartY) {
      const $this = this;

      if (this.activeStep + 1 === this.steps.length) {
        this.activeStep = this.steps.length - 1;
      }

      if (this.activeStep === 0) {
        this.activeStep = 0;
      }
      // if (touchendX < touchstartX) {
      //   console.log('left!');
      // }
      // if (touchendX > touchstartX) {
      //   console.log('right!');
      // }
      // if (touchendY < touchstartY) {
      //   console.log('down!');
      // }
      // if (touchendY > touchstartY) {
      //   console.log('top!');
      // }
      if (touchendY < touchstartY && touchstartY > touchendY + 200) {
        // let vid =  document.querySelector('#video-0');
        // console.log(vid);
        // if (vid) {
        //   vid.play();
        //   // this.videoEnded = false;
        //   vid.addEventListener("ended", function end(e) {
        //     $this.videoEnded = true;
        //     console.log($this.videoEnded);
        //     vid.removeEventListener("ended", end);
        //   });
        // }
      }
      if (touchendX < touchstartX && touchstartX > touchendX + 100) {
        // left
        console.log('left');
        const video = document.querySelector(`#video-${$this.activeStep}`);
        const videoNext = document.querySelector(
          `#video-${$this.activeStep + 1}`
        );
        if (videoNext) {
          console.log(video, videoNext);
          if (this.videoEnded) {
            $this.$refs.c1.goTo($this.activeStep + 1);
            console.log(videoNext);

            videoNext.play();
            this.videoEnded = false;

            videoNext.addEventListener("ended", function end(e) {
              $this.videoEnded = true;
              console.log($this.videoEnded);
              videoNext.removeEventListener("ended", end);
            });
          } else {
            video.play();
            video.playbackRate = 6.0;

            video.addEventListener("ended", function end(e) {
              this.videoEnded = true;
              console.log("The End");
              video.playbackRate = 1.0;
              video.currentTime = 0;
              video.removeEventListener("ended", end);
              setTimeout(() => {
                $this.$refs.c1.goTo($this.activeStep + 1);
                console.log(
                  "to ",
                  document.querySelector(`#video-${$this.activeStep + 1}`)
                );
                let videoAfter = document.querySelector(
                  `#video-${$this.activeStep + 1}`
                );
                videoAfter.play();
                $this.videoEnded = false;
                videoAfter.addEventListener("ended", function end(e) {
                  $this.videoEnded = true;
                  console.log($this.videoEnded);
                  videoAfter.removeEventListener("ended", end);
                });
              }, 300);
            });
          }
        }
      }
      if (touchendX > touchstartX && touchendX > touchstartX + 100) {
        // right
        const video = document.querySelector(`#video-${$this.activeStep}`);
        const videoNext = document.querySelector(
          `#video-${$this.activeStep - 1}`
        );
        console.log(video, videoNext);
        if (this.videoEnded) {
          $this.$refs.c1.goTo($this.activeStep - 1);

          if (videoNext) {
            videoNext.play();
            this.videoEnded = false;
          }

          videoNext.addEventListener("ended", function end(e) {
            $this.videoEnded = true;
            console.log($this.videoEnded);
            videoNext.removeEventListener("ended", end);
          });
        } else {
          video.playbackRate = 6.0;

          video.addEventListener("ended", function end(e) {
            this.videoEnded = true;
            console.log("The End");
            video.playbackRate = 1.0;
            video.currentTime = 0;
            video.removeEventListener("ended", end);
            setTimeout(() => {
              $this.$refs.c1.goTo($this.activeStep - 1);

              console.log(
                "to ",
                document.querySelector(`#video-${$this.activeStep - 1}`)
              );
              let videoAfter = document.querySelector(
                `#video-${$this.activeStep - 1}`
              );
              videoAfter.play();
              $this.videoEnded = false;
              videoAfter.addEventListener("ended", function end(e) {
                $this.videoEnded = true;
                console.log($this.videoEnded);
                videoAfter.removeEventListener("ended", end);
              });
            }, 300);
          });
        }
      }
    },
    showNextDesktop(videoId) {
      console.log(this.activeStepDesk > videoId);
      const $this = this;
      if ($this.activeStepDesk + 1 === this.steps.length) {
        $this.activeStepDesk = this.steps.length - 1;
      }
      if (this.activeStepDesk === 0) {
        this.activeStepDesk = 0;
      }
      function goTo(dir) {
        if (dir) {
          $this.activeStepDesk = videoId;
        } else {
          $this.activeStepDesk = videoId;
        }
      }
      if (this.activeStepDesk > videoId) {
        const video = document.querySelector(`#video-desktop-${videoId}`);
        const videoNext = document.querySelector(
          `#video-desktop-${$this.activeStepDesk}`
        );
        if (video) {
          if (this.videoEnded) {
            goTo(true);
            video.play();
            this.videoEnded = false;
            video.addEventListener("ended", function end(e) {
              $this.videoEnded = true;
              video.removeEventListener("ended", end);
            });
          } else {
            videoNext.playbackRate = 6.0;
            console.log(videoNext.playbackRate);
            videoNext.addEventListener("ended", function end(e) {
              console.log("The End");
              videoNext.playbackRate = 1.0;
              $this.videoEnded = true;
              videoNext.removeEventListener("ended", end);
              setTimeout(() => {
                goTo(true);
                console.log("to");
              }, 300);
            });
          }
        }
      } else {
        const video = document.querySelector(
          `#video-desktop-${$this.activeStepDesk}`
        );
        const videoNext = document.querySelector(`#video-desktop-${videoId}`);
        console.log(videoNext);
        console.log(this.videoEnded);
        if (videoNext) {
          if (this.videoEnded) {
            goTo(true);
            videoNext.play();
            this.videoEnded = false;
            videoNext.addEventListener("ended", function end(e) {
              $this.videoEnded = true;
              videoNext.removeEventListener("ended", end);
            });
          } else {
            video.playbackRate = 6.0;
            console.log(videoNext.playbackRate);
            video.addEventListener("ended", function end(e) {
              console.log("The End");
              video.playbackRate = 1.0;
              $this.videoEnded = true;
              video.removeEventListener("ended", end);
              setTimeout(() => {
                goTo(true);
                console.log("to");
                document
                  .querySelector(`#video-desktop-${$this.activeStepDesk}`)
                  .play();
                $this.videoEnded = false;
              }, 300);
            });
          }
        }

        // if (video) {

        // } else {
        //   goTo(true);
        //   if (videoNext) {
        //     videoNext.play();
        //   }
        // }
      }
    },
  },
  mounted() {
    let scroll = false;
    let $this = this;
    let cont = document.querySelector(".steps-detail-desktop");
    let stepsItems = cont.querySelectorAll(".steps-detail__item");
    stepsItems[0].classList.add("_active");

    setTimeout(() => {
      let iframes = document.querySelector(".steps").querySelectorAll("video");
      for (let index = 0; index < iframes.length; index++) {
        const element = iframes[index];
        if (element.getAttribute("src") == "") {
          element.setAttribute("src", element.getAttribute("data-path"));
        }
      }
    }, 2000);

    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;

    setTimeout(() => {
      let touchendX = 0;
      let touchstartX = 0;
      let touchendY = 0;
      let touchstartY = 0;

      let gesuredZoneNav = document.querySelector(".steps-nav-mobile");
      let gesuredZone = document.querySelector(".steps-detail-mobile");

      gesuredZone.addEventListener(
        "touchstart",
        function (event) {
          touchstartX = event.touches[0].screenX;
          touchstartY = event.touches[0].screenY;
        },
        false
      );
      gesuredZone.addEventListener(
        "touchend",
        function (event) {
          touchendX = event.changedTouches[0].screenX;
          touchendY = event.changedTouches[0].screenY;
          $this.showNextBySwipe(touchendX, touchstartX, touchendY, touchstartY);
        },
        false
      );
      gesuredZoneNav.addEventListener(
        "touchstart",
        function (event) {
          touchstartX = event.touches[0].screenX;
        },
        false
      );
      gesuredZoneNav.addEventListener(
        "touchend",
        function (event) {
          touchendX = event.changedTouches[0].screenX;
          $this.showNextBySwipe(touchendX, touchstartX);
        },
        false
      );
    }, 500);
  },
  watch: {
    videoFinish(val) {
      this.videoEnded = val;
    },
    activeStepDesk(val) {
      let cont = document.querySelector(".steps-detail-desktop");
      let steps = cont.querySelectorAll(".steps-detail__item");
      let newVal = val;
      steps.forEach((element) => {
        element.classList.remove("_active");
      });
      steps[newVal].classList.add("_active");
      document.getElementById(`video-desktop-${newVal}`).play();
      setTimeout(() => {
        if (!steps[newVal].classList.contains("_passed")) {
          steps[newVal].className += " _passed";
        }
        // if (newVal == 2) {
        //   if (isMobile()) {
        //     this.play("anim-mobile");
        //   } else {
        //     this.play("anim-desktop");
        //   }
        // }
      }, 0);
    },
  },
};
</script>

<style lang="scss">
.video::-webkit-media-controls,
.video::-moz-media-controls,
.video::-o-media-controls,
.video::-ms-media-controls,
.video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
.video {
    -webkit-filter: contrast(100%);
    pointer-events: none;
}
.steps {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: rem(70px) 0 0;
  background: rgb(249 226 209);
  overflow: hidden;
  z-index: 2;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 9;
  }
  &__title.section__title {
    margin-bottom: rem(30px);
    color: #9e0d53;
  }
  &__content {
    position: relative;
    display: flex;
    flex: auto;
    max-width: 100%;
    height: 100%;
    z-index: 9;
  }
  &-nav {
    position: relative;
    display: flex;
    max-width: 100%;
    margin-bottom: rem(-30px);
    flex-direction: row;
    align-items: flex-start;
    z-index: 1;
    &__item {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 100%;
      max-width: 100%;
      height: rem(103px);
      padding-top: rem(10px);
      padding-left: rem(13px);
      padding-right: rem(10px);
      margin-bottom: rem(25px);
      color: #fff;
      background: #cc085a;
      opacity: 0.5;
      transition: 0.4s;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        display: block;
        @include size(rem(40px));
        background: #cc085a;
        transform: rotate(45deg) translate(-50%, -50%);
        z-index: -1;
      }
      &-wrapper {
        padding: 0 rem(2px) rem(20px);
      }
      &-hidden {
        position: relative;
        flex: auto;
      }
      &-col {
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        iframe {
          position: relative;
          right: rem(-20px);
        }
      }

      &-index {
        display: block;
        width: rem(26px);
        margin-right: rem(7px);
        font-family: "Avenir-Bold";
        font-size: rem(46px);
        line-height: 1;
      }
      &-descr {
        margin-top: rem(5px);
        min-height: rem(40px);
        font-family: "Demi";
        font-size: rem(17px);
        line-height: rem(18px);
        transition: 0.4s;
        transform-origin: left top;
        a,
        span {
          color: $pink;
          font-family: "Bold";
          text-decoration: none;
        }
        a {
          border-bottom: 1px solid currentColor;
        }
      }
      ._active &,
      .slick-current & {
        opacity: 1;
      }
    }
  }
  &__btn.btn {
    width: rem(240px);
    height: rem(50px);
    display: flex;
    margin: 0 auto rem(38px);
    align-items: center;
    justify-content: center;
    line-height: rem(45px);
    text-transform: none;
    font-size: rem(18px);
    letter-spacing: .5px;
    background: #CC085A;
    border-color: #CC085A;
    box-shadow: 0px 5px rem(10px) rgba(99, 19, 19, 0.58);
    img {
      height: 70%;
      margin-right: rem(12px);
    }
  }
  &-detail {
    position: relative;
    flex: auto;
    &__item {
      img {
        display: block;
        max-width: 100%;
        margin: auto;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &__single-product {
      width: 70%;
      margin: auto 0;
    }
    video {
      width: 100%;
      height: 70vh;
      max-height: 100%;
      // border: 1px solid #000;
    }
    &__phone-example {
      // position: absolute;
      // bottom: 0;
      // right: 13%;
      width: 60%;
      height: auto;
      max-height: 100%;
      margin: auto;
      object-fit: contain;
      transform: translateY(100%);
      transition: 0.4s;
      transition-delay: 0.5s;
      ._passed & {
        transform: translateY(0%);
      }
    }
  }
  &__list {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin: 0 auto;
  }

  &__item-title {
    font-size: rem(16px);
    font-family: "Bold";
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  @media (max-width: $md) {
    .container {
      padding-right: 0;
    }
    &-nav {
      &__item {
        &:last-of-type {
          margin-bottom: 0;
          flex: auto;
        }
      }
    }
    &-production-slider {
      max-width: 100vw !important;
    }
  }
  @media (min-width: $sm) {
    &-production-slider {
      left: rem(-65px);
    }
    &-nav {
      margin-bottom: 0;
      &__item {
        padding-left: rem(50px);
        &-index {
          font-size: rem(23px);
          line-height: rem(30px);
        }
        &-descr {
          font-size: rem(18px);
          line-height: rem(24px);
        }
      }
    }
  }
  @media (min-width: $sm) {
  }
  @media (min-width: $md) {
    padding: rem(55px) 0 rem(65px);
    background: rgb(251 227 208);
    &__list {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: flex-start;
      max-width: rem(970px);
      padding-bottom: rem(70px);
      margin: 0 auto;
    }
    &__title.section__title {
      margin-bottom: rem(50px);
      span {
        font-size: rem(48px);
      }
    }
    &-dots {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__dot {
        @include size(rem(18px));
        display: block;
        margin: rem(6px) 0;
        background: #cc085a;
        opacity: 0.5;
        border: rem(3px) solid #f8decb;
        border-radius: 50%;
        transition: 0.3s;
        cursor: pointer;
        &.active {
          opacity: 1;
          border-color: #cc085a;
        }
      }
    }
    &-nav {
      flex: 0 0 33%;
      flex-direction: column;
      margin-right: 3%;
      &__item {
        height: rem(156px);
        align-items: center;
        margin-bottom: rem(10px);
        padding-top: rem(0px);
        padding-left: rem(13px);
        &::after {
          left: 100%;
          top: 50%;
          @include size(rem(40px));
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &-wrapper {
          width: 100%;
          padding: 0;
        }
        &-index {
          width: rem(50px);
          margin-right: rem(20px);
          font-size: rem(67px);
          line-height: rem(100px);
        }
        &-button.btn {
          margin-top: rem(6px);
          min-width: auto;
          height: rem(43px);
          line-height: rem(40px);
          font-size: rem(24px);
          margin-bottom: 0;
        }
        &-descr {
          margin-top: 0;
          font-size: rem(24px);
          line-height: rem(29px);
          opacity: 1;
        }
      }
    }
    &-detail {
      &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        margin-top: 0;
        opacity: 0;
        &._active {
          transition: 0.4s;
          opacity: 1;
        }
        video {
          margin: auto;
          max-width: 100%;
          height: 100%;
        }
      }
    }
    &__item-index {
      width: rem(45px);
      height: rem(45px);
      font-size: rem(30px);
      line-height: 1;
    }
    &__rules {
      margin-left: rem(70px);
      color: #fff;
      font-size: rem(18px);
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  &-production {
    height: 100%;
    img {
      max-height: 50%;
    }
  }
}
iframe {
  width: 100%;
}
</style>
