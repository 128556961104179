<template lang="pug">
section.prizes.section#prizes
  .section__title.visible-xs.gradient-text Получить призы
  .container
    .prizes-block( v-if="isMobile" data-show )
      .prizes-steps
        .prizes-steps__item(v-for="(item, i) in steps" :key="i")
          .prizes-steps__item-index.gradient-text {{ i+1 }}
          .prizes-steps__item-text {{ item }}
          .prizes-steps__item-button(v-if="i === 1")
            #button
            #scanner
    .prizes-block(data-show)
      .prizes__col
        .prizes-block__title 
          svg.visible-xs(width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg")
            path(d="M18.5 16L0.74648 0.25L36.2535 0.250003L18.5 16Z" fill="url(#paint0_linear_738_2085)")
            defs
              linearGradient(id="paint0_linear_738_2085" x1="6.48899" y1="16" x2="34.5654" y2="5.29204" gradientUnits="userSpaceOnUse")
                stop(stop-color="#E2B758")
                stop(offset="0.510417" stop-color="#FFF5E0")
                stop(offset="0.84375" stop-color="#E2B758")
          span Гарантированно
        .prizes__list.garant
          .prizes__item( v-for="(item, index) in prizesGarant" :key="index" )
            .prizes__item-img-wrapper
              img.prizes__item-img(
                v-if="item.img2x"
                :src="require(`../assets/images/prizes/${item.img}`)"
                :srcset="`${item.img2x} 2x`"
                alt=""
              )
              img.prizes__item-img(
                v-else-if="item.img"
                :src="require(`../assets/images/prizes/${item.img}`)"
                alt=""
              )

            .prizes__item-info
              p.prizes__item-text.gradient-text(
                v-if="item.text"
                v-html="item.text"
              )
      .prizes__col
        .prizes-block__title 
          svg.visible-xs(width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg")
            path(d="M18.5 16L0.74648 0.25L36.2535 0.250003L18.5 16Z" fill="url(#paint0_linear_738_2086)")
            defs
              linearGradient(id="paint0_linear_738_2086" x1="6.48899" y1="16" x2="34.5654" y2="5.29204" gradientUnits="userSpaceOnUse")
                stop(stop-color="#E2B758")
                stop(offset="0.510417" stop-color="#FFF5E0")
                stop(offset="0.84375" stop-color="#E2B758")
          span Еженедельно
        .prizes__list.week
          .prizes__item( v-for="(item, index) in prizesWeekly" :key="index" )
            .prizes__item-img-wrapper
              img.prizes__item-img(
                v-if="item.img2x"
                :src="require(`../assets/images/prizes/${item.img}`)"
                :srcset="`${item.img2x} 2x`"
                alt=""
              )
              img.prizes__item-img(
                v-else-if="item.img"
                :src="require(`../assets/images/prizes/${item.img}`)"
                alt=""
              )

            .prizes__item-info
              p.prizes__item-text.gradient-text(
                v-if="item.text"
                v-html="item.text"
              )

    .prizes-block.main( data-show )
      .prizes__col
        .prizes-block__title 
          svg(width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg")
            path(d="M18.5 16L0.74648 0.25L36.2535 0.250003L18.5 16Z" fill="url(#paint0_linear_738_2087)")
            defs
              linearGradient(id="paint0_linear_738_2087" x1="6.48899" y1="16" x2="34.5654" y2="5.29204" gradientUnits="userSpaceOnUse")
                stop(stop-color="#E2B758")
                stop(offset="0.510417" stop-color="#FFF5E0")
                stop(offset="0.84375" stop-color="#E2B758")
          span Главный приз
        .prizes__list
          .prizes__item( v-for="(item, index) in prizesMain" :key="index" )
            .prizes__item-img-wrapper
              img.prizes__item-img(
                v-if="item.img2x"
                :src="require(`../assets/images/prizes/${item.img}`)"
                :srcset="`${item.img2x} 2x`"
                alt=""
              )
              img.prizes__item-img(
                v-else-if="item.img"
                :src="require(`../assets/images/prizes/${item.img}`)"
                alt=""
              )

            .prizes__item-info
              p.prizes__item-text.gradient-text(
                v-if="item.text"
                v-html="item.text"
              )
</template>

<script>
import AppScrollDown from "./AppScrollDown.vue";
export default {
  data: () => ({
    isMobile: null,
    steps: ["Покупайте «Коркунов»", "СКАНИРУЙте ЧЕК В «Едадил»", "Выигрывайте"],
    prizesWeekly: [
      {
        img: "prize_1.png",
        img2x: require("../assets/images/prizes/prize_1@2x.png"),
        text: "Сертификат на покупку<br>электронной книги",
      },
      {
        img: "prize_2.png",
        img2x: require("../assets/images/prizes/prize_2@2x.png"),
        text: "Подарочный сертификат",
      },
      {
        img: "prize_3.png",
        img2x: require("../assets/images/prizes/prize_3@2x.png"),
        text: "Брендированная<br>толстовка",
      },
    ],
    prizesGarant: [
      {
        img: "prize_4.png",
        img2x: require("../assets/images/prizes/prize_4@2x.png"),
        text: "Кешбэк до 10%",
      },
    ],
    prizesMain: [
      {
        img: "prize_5.png",
        img2x: require("../assets/images/prizes/prize_5@2x.png"),
        text: "<span>100 000</span> руб. <br>для организации идеального <br>поздравления тому, кто важен",
      },
    ],
  }),
  mounted() {
    this.isMobile = window.innerWidth < 768;
  },
  components: { AppScrollDown },
};
</script>

<style lang="scss">
.prizes {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: rem(20px);
  padding-top: rem(55px);
  background: radial-gradient(
      75.13% 50% at 50% 50%,
      rgba(241, 17, 89, 0.4) 0%,
      rgba(241, 17, 89, 0) 100%
    ),
    #9e0d53;
  overflow: hidden;
  .container {
    position: relative;
    padding-top: rem(20px);
    padding-bottom: rem(30px);
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #e2b758, #fff5e0, #e2b758);
    z-index: 2;
  }
  &__title {
    margin-bottom: rem(30px);
    text-align: center;
    color: #fff;
    letter-spacing: rem(1px);
    font-size: rem(12px);
    text-transform: uppercase;
    line-height: 1.3;
    span {
      display: block;
      font-size: rem(18px);
    }
  }
  &__col {
    width: 100%;
    flex: 100%;
  }
  &-steps {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: rem(10px);
      text-transform: uppercase;
      &-index {
        display: block;
        margin-bottom: rem(5px);
        font-size: rem(33px);
        line-height: rem(45px);
        font-family: "Avenir-Bold";
      }
      &-button {
        display: flex;
        align-items: center;
        margin-top: rem(19px);
        .info-button {
          &__button {
            border-radius: 0;
          }
          &__wrapper {
            display: flex;
            align-items: center;
            width: rem(200px);
            height: rem(54px);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            &::before {
              display: block;
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              background: linear-gradient(45deg, transparent, #FFF, #FFF);
              width: 15%;
            }
          }
          &__image-wrapper {
            flex-shrink: 0;
          }
          &__info {
            flex: auto;
          }
          &__title {
            color: #47AB49;
            font-family: "Avenir-Bold";
          }
        }
        .scan-button {
          &__wrapper {
            @include size(rem(54px));
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          &__button {
            border-radius: 0;
          }
        }
      }
    }
  }
  &-block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &__title {
      width: 100%;
      flex-shrink: 0;
      margin-bottom: rem(19px);
      text-align: center;
      color: #fff;
      font-size: rem(19px);
      svg {
        width: rem(21px);
        margin: rem(26px) auto rem(17px);
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  &__item {
    width: 48%;
    flex: 0 0 48%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .week & {
      &:last-child {
        width: 50%;
        flex: 0 0 70%;
        margin-top: rem(45px);
        .prizes__item-img-wrapper {
          @include size(rem(144px));
        }
      }
    }
    .main & {
      width: 70%;
      flex: 0 0 70%;
    }
    &-img {
      display: block;
      @include size(100%);
      object-fit: contain;
      &-wrapper {
        position: relative;
        @include size(rem(142px));
        margin: 0 auto rem(18px);
        flex-shrink: 0;
        .garant & {
          @include size(rem(73px));
        }
        .week & {
          @include size(rem(102px));
        }
        .main & {
          @include size(rem(216px));
        }
      }
    }
    &-info {
      flex: auto;
    }
    &-text {
      font-family: "Demi";
      font-size: rem(14px);
      line-height: rem(16px);
      text-align: center;
      text-transform: none;
      span {
        font-size: rem(16px);
        text-transform: uppercase;
      }
    }
  }

  &__item-title {
    text-align: center;
    text-transform: uppercase;
    font-size: rem(18px);
    font-family: "Bold";
    margin-bottom: rem(20px);
  }

  @media (min-width: $sm) {
    padding: rem(85px) 0 rem(20px);
    align-items: center;
    &__item {
      flex: 0 0 auto;
    }
    &__item-img-wrapper {
      height: rem(143px);
    }
  }
  @media (min-width: $md) {
    position: relative;
    align-items: flex-start;
    padding: rem(130px) 0 rem(80px);
    background: url("../assets/images/prizes/background.jpg") no-repeat center
      bottom;
    background-size: cover;
    z-index: 0;
    .container {
      padding-top: 0;
      height: 100%;
      border: none;
    }
    &-block {
      flex-direction: row;
      align-items: stretch;
      &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        font-size: rem(37px);
        margin-bottom: 0;
        line-height: rem(50px);
        .main & {
          font-size: rem(52px);
          line-height: rem(71px);
        }
        svg {
          width: rem(41px);
          margin-top: rem(70px);
          margin-bottom: rem(25px);
        }
      }
    }
    &__col {
      flex: auto;
      width: auto;
    }
    &__list {
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
    &__title {
      margin-bottom: rem(50px);
      font-size: rem(26px);
      letter-spacing: rem(3px);
      line-height: 1.1;
      span {
        font-size: rem(45px);
      }
    }
    &__item {
      flex: 0 0 33%;
      width: auto;
      padding-right: rem(20px);
      text-align: center;
      .week & {
        &:last-child {
          width: auto;
          flex: 0 0 33%;
          margin-top: 0;
          .prizes__item-img {
            width: 100%;
            &-wrapper {
              @include size(rem(245px));
            }
          }
        }
      }
      .main & {
        width: auto;
        flex: 0 0 33%;
      }
      .garant & {
        width: auto;
        flex: 0 0 100%;
      }
      &-img {
        .week & {
          width: 80%;
        }
        &-wrapper {
          margin: 0 auto;
          @include size(rem(228px));
          .week & {
            @include size(rem(235px));
          }
          .main & {
            @include size(rem(320px));
          }
          .garant & {
            margin-top: rem(10px);
            @include size(rem(148px), rem(235px));
          }
        }
      }
      &-text {
        font-size: rem(24px);
        line-height: rem(24px);
        span {
          font-size: rem(36px);
          line-height: 1;
        }
      }
    }
  }
}
</style>
