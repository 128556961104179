<template lang="pug">
.footer(:class="className")
  .container
    router-link(:to="{ path: '/', hash: '#home'}").footer__to-top
      svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
        path(d="M8 15V1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
        path(d="M1 8L8 1L15 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
      span Наверх
    .footer__row
      .footer__nav
        a.footer__nav-link(
          href="https://www.mars.com/privacy-policy-russia"
          target="_blank"
        ) Конфиденциальность
        a.footer__nav-link(
          href="https://www.mars.com/legal-russia"
          target="_blank"
        ) Юридические условия
        a.footer__nav-link(
          href="https://www.mars.com/"
          target="_blank"
        ) Владелец сайта
        </a>
        a.footer__nav-link(href="" @click.prevent="toFeedback()") Задать вопрос о продукте
        a.footer__nav-link(
          :href="`${publicPath}docs/user_agreement.pdf`"
          target="_blank"
        ) Пользовательское соглашение
    p.footer__copyright {{ `© ${year} Mars, Incorporated. Все права защищены` | typograf }}
</template>
<script>
export default {
  props: ["className"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    toFeedback() {
      this.$modal.show("feedback");
    },
  },
  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  padding: rem(20px) 0 rem(30px);
  text-align: left;
  z-index: 10;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  &__to-top {
    display: flex;
    @include size(rem(88px));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: rem(50px);
    margin-bottom: rem(46px);
    background: #cc085a;
    color: #fff;
    font-size: rem(13px);
    line-height: rem(15px);
    border-radius: 50%;
    box-shadow: rem(5px) rem(5px) rem(25px) rgba(0, 0, 0, 0.3);
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-link {
    position: relative;
    margin-bottom: rem(5px);
    font-size: rem(14px);
    text-align: center;
    line-height: rem(22px);
    color: #fff;
  }

  &__nav-link:last-child {
    margin-right: 0;
  }

  &__nav-link:hover {
    text-decoration: none;
  }

  &__copyright {
    margin: rem(10px) auto 0;
    font-size: rem(12px);
    text-align: center;
    color: #fff;
    font-family: "Avenir-Light";
  }
  @media (min-width: $md) {
    padding: rem(0px) 0 rem(80px);
    &__to-top {
      @include size(rem(118px));
      margin-right: rem(60px);
      margin-bottom: rem(80px);
      font-size: rem(18px);
      line-height: rem(20px);
    }
    &__row {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__nav {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &__nav-link {
      margin-bottom: 0;
      font-size: rem(16px);
      text-align: left;
    }
    &__copyright {
      margin: rem(30px) auto 0;
    }
  }
}
</style>
