<template lang="pug">
header.header
  .container
    .header__row
      router-link.header__logo-wrapper(:to="{ path: '/', hash: '#home' }")
        img.header__logo._left.hidden-xs(src='@/assets/images/logo.png' alt='')
        img.header__logo._left.visible-xs(src='@/assets/images/logo-big.png' alt='')
      .header__inner
        .header__hamburger(@click='showBurger()')
          svg(width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg")
            path(d="M24.4062 2.32617H2.46875" stroke="#FFF7F4" stroke-width="2.4375" stroke-linecap="round" stroke-linejoin="round")
            path(d="M24.4062 9.64648H9.78125" stroke="#FFF7F4" stroke-width="2.4375" stroke-linecap="round" stroke-linejoin="round")
            path(d="M24.4062 16.9688H7.34375" stroke="#FFF7F4" stroke-width="2.4375" stroke-linecap="round" stroke-linejoin="round")
        ul.header__nav
          li.header__nav-link(
            v-for='nav in navigation' 
            :key='nav.name' 
            :data-menuanchor='nav.anchor' 
            @click="nav.gtm ? gtmEvent(nav.anchor || 'rules', 'click', 'internal') : ''" 
            :class='{ _active: $route.hash === `#${nav.anchor}` }'
          )
            router-link(v-if='nav.anchor' :to="{ path: '/', hash: `#${nav.anchor}` }")
              | {{ nav.label }}
            a.d-flex.aic(v-else-if='nav.path' :href='publicPath + nav.path' target='_blank')
              | {{ nav.label }}
              svg(
                width='11' 
                height='11' 
                viewbox='0 0 11 11' 
                fill='none' 
                xmlns='http://www.w3.org/2000/svg'
              )
                path(d='M11 10.3125C11 10.6922 10.6922 11 10.3125 11H0.6875C0.307807 11 0 10.6922 0 10.3125C0 9.93281 0.307807 9.625 0.6875 9.625H10.3125C10.6922 9.625 11 9.93281 11 10.3125ZM5.01387 8.13459C5.14813 8.26884 5.32404 8.33596 5.5 8.33596C5.67591 8.33596 5.85191 8.26882 5.98613 8.13459L8.42198 5.69875C8.69047 5.43026 8.69047 4.99497 8.42198 4.72648C8.15349 4.45799 7.7182 4.45799 7.44971 4.72648L6.1875 5.98868V0.6875C6.1875 0.307807 5.87969 0 5.5 0C5.12031 0 4.8125 0.307807 4.8125 0.6875V5.98868L3.55029 4.72648C3.2818 4.45799 2.84651 4.45799 2.57802 4.72648C2.30953 4.99497 2.30953 5.43026 2.57802 5.69875L5.01387 8.13459Z' fill='white')
            router-link(v-else='' :to="{ path: '/' }")
              | {{ nav.label }}
          li
            router-link.header__nav-link._accent(:to="{ path: '', hash: '#edadil' }" :class="{ _active: $route.hash == '#edadil' }")
              img(src='../assets/images/edadil_logo.svg' alt='')
              span Акция в Едадил
      .header__shadow(@click='showBurger()')
      .header__menu_mob
        button.header__menu_mob-close(@click='showBurger()')
        .header__nav-link(v-for='nav in navigation' :key='nav.name' :data-menuanchor='nav.anchor' @click="nav.gtm ? gtmEvent(nav.anchor || 'rules', 'click', 'internal') : '', showBurger()" :class='{ _active: $route.hash === `#${nav.anchor}` }')
          router-link(v-if='nav.anchor' :to="{ path: '/', hash: `#${nav.anchor}` }")
            | {{ nav.label }}
          a.d-flex.aic(v-else-if='nav.path' :href='publicPath + nav.path' target='_blank')
            | {{ nav.label }}
            svg(width='11' height='11' viewbox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M11 10.3125C11 10.6922 10.6922 11 10.3125 11H0.6875C0.307807 11 0 10.6922 0 10.3125C0 9.93281 0.307807 9.625 0.6875 9.625H10.3125C10.6922 9.625 11 9.93281 11 10.3125ZM5.01387 8.13459C5.14813 8.26884 5.32404 8.33596 5.5 8.33596C5.67591 8.33596 5.85191 8.26882 5.98613 8.13459L8.42198 5.69875C8.69047 5.43026 8.69047 4.99497 8.42198 4.72648C8.15349 4.45799 7.7182 4.45799 7.44971 4.72648L6.1875 5.98868V0.6875C6.1875 0.307807 5.87969 0 5.5 0C5.12031 0 4.8125 0.307807 4.8125 0.6875V5.98868L3.55029 4.72648C3.2818 4.45799 2.84651 4.45799 2.57802 4.72648C2.30953 4.99497 2.30953 5.43026 2.57802 5.69875L5.01387 8.13459Z' fill='white')
          router-link(v-else='' :to="{ path: '/' }")
            | {{ nav.label }}
        //
          <a
          class="header__nav-link"
          :href="`${publicPath}docs/rules.pdf`"
          target="_blank"
          >
          <span>Правила акции Едадил</span>
          </a>
        router-link.header__nav-link._accent(
          :to={ path: '/', hash: '#edadil'} 
          @click='showBurger()' 
          :class="{ _active: $route.hash == '#edadil' }"
        )
          img(src='../assets/images/edadil_logo.svg' alt='')
          span Акция в Едадил
        .header__show-example(@click="$modal.show('video_popup'), showBurger()")
          svg(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
            circle(cx='12' cy='12' r='11.5' stroke='#9E0D53')
            path(d='M18 12L9 17.1962L9 6.80385L18 12Z' fill='#9E0D53')
          span Посмотреть пример поздравления

</template>

<script>
export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    dropdownShow: false,
    navigation: [
      {
        label: "Главная",
        anchor: "home",
      },
      {
        label: "Как создать поздравление?",
        anchor: "create",
      },
      {
        label: "Получить призы",
        anchor: "prizes",
      },
      {
        label: "Узнать, кто вы из писателей",
        anchor: "test",
      },
      {
        label: "Архив",
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    clickPrize() {
      if (this.$store.getters.user) {
        let apmButton = document.querySelector(
          "#apm-scan-qr .apm-action-button"
        );
        if (apmButton) apmButton.click();
      } else {
        this.$modal.show("authorization");
      }
    },

    showModal(name) {
      this.$modal.show(name);
    },

    showBurger() {
      let header = document.querySelector(".header");
      if (!header.classList.contains("header--mob_active"))
        header.classList.add("header--mob_active");
      else header.classList.remove("header--mob_active");
    },
    click() {
      this.$emit("update", true);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.4s;
  z-index: 999;
  &._modified {
    transform: translateY(rem(-40px));
  }
  .container {
    position: relative;
    padding-top: rem(56px);
    padding-bottom: rem(17px);
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &.header--mob_active {
    .header__menu_mob {
      transform: translateX(0%);
    }
  }
  &__logo {
    height: rem(33px);
    transition: 0.4s;
    &-wrapper {
      margin: auto;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__nav {
    position: relative;
    width: 100%;
    display: none;
    align-items: center;
  }

  &__nav-link {
    position: relative;
    display: flex;
    align-items: center;
    height: rem(30px);
    padding-left: rem(30px);
    font-size: rem(14px);
    line-height: 1;
    color: #fff;
    transition: all 0.3s ease-in-out;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% - #{rem(30px)});
      height: 1px;
      display: block;
      background: #fff;
      opacity: 0;
    }
    &.router-link-exact-active,
    &._active {
      &::before {
        opacity: 1;
      }
    }
    &._accent {
      display: flex;
      width: calc(100% - #{rem(34px)});
      height: auto;
      align-items: center;
      margin-top: rem(10px);
      margin-bottom: rem(20px);
      padding: rem(7px) rem(30px);
      margin-left: 0;
      background: #47ab49;
      img {
        height: rem(30px);
        margin-right: rem(10px);
      }
      &::before {
        display: none;
      }
    }
    &._green {
      color: #48a237;
    }
    &._orange {
      color: #ff7e00;
    }
  }

  &__nav-link:not(._exit):hover {
    border-bottom-color: currentColor;
  }
  &__hamburger {
    position: absolute;
    right: 0;
    display: flex;
    @include size(rem(50px));
    margin: auto 0 auto auto;
    svg {
      display: block;
      margin: auto;
      width: rem(21px);
    }
  }
  &__show-example {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - #{rem(34px)});
    padding: rem(7px) rem(30px);
    color: #9e0d53;
    font-size: rem(14px);
    line-height: rem(16px);
    letter-spacing: rem(1px);
    background: linear-gradient(
        258.95deg,
        #e2b758 17.32%,
        #fff5e0 50.82%,
        #e2b758 72.69%
      ),
      #e2b758;
    transition: 0.4s;
    pointer-events: all;
    cursor: pointer;
    z-index: 2;
    svg {
      width: rem(24px);
      margin-right: rem(13px);
      flex-shrink: 0;
    }
  }
  &__menu_mob {
    display: flex;
    position: absolute;
    width: 90%;
    top: 0px;
    right: 0;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    background-color: #cc085a;
    padding-top: 0;
    transform: translateX(100%);
    padding-bottom: rem(37px);
    transition: 0.4s;
    z-index: 10;
    &-close {
      cursor: pointer;
      align-self: flex-end;
      margin: rem(70px) rem(21px) rem(32px) 0;
      width: rem(34px);
      height: rem(34px);
      flex-shrink: 0;
      margin-bottom: rem(30px);
      background: url("../assets/images/icons/close.svg") no-repeat center;
      background-size: rem(22px);
    }
    .btn--default {
      background-color: transparent;
    }

    .btn {
      margin-bottom: 21px;
      min-width: 280px;
    }

    .btn:last-child {
      margin-top: 20px;
    }
  }
  &__shadow {
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background: rgba(#4a0e25, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
    z-index: 0;
    .header--mob_active & {
      opacity: 1;
      pointer-events: all;
      cursor: pointer;
    }
  }
  @media (min-width: $sm) {
    &__logo {
      height: rem(38px);
    }
    &__menu_mob {
      max-width: 45%;
      box-shadow: -5px 4px 10px rgba(0, 0, 0, 0.15);
    }
    &__menu_mob-close {
      background-image: url("../assets/images/close_icon.svg");
    }
  }
  @media (min-width: $md) {
    .container {
      max-width: 95%;
      padding-top: rem(40px);
    }
    &__row {
      align-items: center;
    }
    &__logo {
      height: rem(87px);
      transform-origin: left center;
      &-wrapper {
        margin-left: 0;
      }
      ._modified & {
        transform: scale(0.7);
      }
    }
    &__nav {
      display: flex;
      align-items: center;
      &-dropdown {
        position: relative;
        margin-left: rem(26px);
        z-index: 10;
        &-trigger {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 rem(24px);
          cursor: pointer;
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: rem(66px);
            position: absolute;
            top: rem(-18px);
            left: 0;
            background: #fff;
            border-radius: rem(10px) rem(10px) 0 0;
            opacity: 0;
            transition: 0.4s;
            z-index: -1;
          }
          span {
            margin-right: rem(5px);
            color: #fff;
            transition: 0.4s;
            ._open & {
              color: $red;
            }
          }
          svg {
            transition: 0.4s;
            ._open & {
              transform: rotate(180deg);
            }
          }
          path {
            stroke: #fff;
            transition: 0.4s;
            ._open & {
              stroke: $red;
            }
          }
          ._open & {
            &::before {
              opacity: 1;
            }
          }
        }
        &-list {
          position: absolute;
          top: calc(100% + #{rem(23px)});
          right: 0;
          padding: rem(17px) rem(24px);
          background: #fff;
          border-radius: rem(10px) 0 rem(10px) rem(10px);
          transition: 0.4s;
          opacity: 0;
          pointer-events: none;
          ._open & {
            opacity: 1;
            pointer-events: all;
          }
        }
        &-item {
          white-space: nowrap;
          color: $red;
          &:not(:last-of-type) {
            margin-bottom: rem(11px);
          }
          span {
            border-bottom: 2px solid transparent;
          }
          &:hover {
            span {
              border-color: $red;
            }
          }
        }
      }
    }
    &__nav-link {
      position: relative;
      width: auto;
      height: auto;
      display: inline;
      font-size: rem(14px);
      margin-left: rem(30px);
      white-space: nowrap;
      padding: 0;
      margin-bottom: 0;
      line-height: 1.4;
      text-shadow: 1px 1px 3px rgba(#000, 0.5);
      color: #fff;
      text-transform: none;
      &::before {
        content: "";
        position: absolute;
        top: calc(100% + rem(2px));
        left: 0;
        width: 100%;
        height: rem(1px);
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 0px 3px rgba(#000, 0.5);
        background: #fff;
        opacity: 0;
      }
      &.router-link-exact-active,
      &._active {
        background: none;
        color: #fff;
        &::before {
          opacity: 1;
        }
      }
      &._accent {
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: rem(50px);
        padding: rem(7px) rem(35px) rem(7px) rem(12px);
        background: $green;
        letter-spacing: normal;
        border: none;
        font-size: rem(14px);
        img {
          width: rem(30px);
          height: rem(30px);
          margin-right: rem(21px);
        }
        &::before {
          display: none;
        }
        &._active {
          b span {
            border-bottom-color: #48a237;
          }
        }
      }
    }

    .container {
      display: flex;
    }

    &__nav_mob {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__hamburger,
    &__menu_mob {
      display: none;
    }
  }
  @media (min-width: $max) {
    .container {
      max-width: 90%;
    }
  }
}

.header__menu_mob.active {
  display: flex !important;
}
</style>
