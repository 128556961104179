<template>
  <Modal name="test_popup" size="lg" @closeModal="closeModal">
    <template v-slot:content>
      <div class="modal__subtitle">
        Узнайте, кто вы <br class="visible-xs" />из&nbsp;писателей
      </div>
      <test />
    </template>
  </Modal>
</template>

<script>
import Test from "../form/test.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
    Test,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>

<style lang="scss">
.modal-test_popup {
  max-width: 100vw;
  margin-top: 0;
  padding: rem(47px) 0 rem(20px);
  background: #f8decb;
  border-radius: 0;
  .modal__subtitle {
    margin-bottom: rem(25px);
    font-size: rem(20px);
    line-height: 1.1;
    letter-spacing: rem(1px);
    text-transform: uppercase;
    color: #9e0d53;
  }
  .modal__close {
    top: rem(10px);
    right: rem(10px);
    @include size(rem(24px));
    background-image: url('../../assets/images/icons/close_small.svg');
    background-size: 100%;
  }
  @media (min-width: $md) {
    margin-top: 10vh;
    padding: rem(30px) 0 rem(20px);
    .modal__subtitle {
      margin-bottom: rem(45px);
      font-size: rem(18px);
    }
  }
}
</style>
