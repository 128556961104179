<template>
  <div>
    <Hero />
    <Steps :videoFinish="videoFinish" :ker="ker"/>
    <AppEdadilPromo v-if="!isMobile" />
    <PrizesBlock />
    <AppTest />
    <AppWhereToBuy />
    <!-- <AppFaq /> -->
    <!-- <AppWinners /> -->
  </div>
</template>

<script>
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
import PrizesBlock from "@/components/AppPrizes.vue";
import AppEdadilPromo from "../components/AppEdadilPromo.vue";
import AppWhereToBuy from "../components/AppWhereToBuy.vue";
// import AppFaq from "../components/AppFaq.vue";
// import AppWinners from "../components/AppWinners.vue";
import AppTest from "../components/AppTest.vue";

export default {
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      isMobile: true,
      videoFinish: true,
      ker: 1,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    onResize() {
      let $this = this;
      $this.isMobile = window.innerWidth < 800;
    },
  },
  mounted() {
    let $this = this;
    window.addEventListener("resize", () => {
      this.onResize, { passive: true };
      this.onResize();
    });
    // if (this.$route.hash === 'create') {
    //   if (window.innerWidth >= 1024) {
    //     document.querySelector(`#video-desktop-0`).play();
    //   } else {
    //     document.querySelector(`#video-0`).play();
    //   }
    // }
    // if (window.innerWidth >= 1024) {
    const sections = document.querySelectorAll(".section");
    const links = document.querySelectorAll("[data-menuanchor]");
    let videoPlay = false;
    let vid = document.getElementById("video-0");
    document.addEventListener("scroll", (e) => {
      sections.forEach((ha) => {
        const rect = ha.getBoundingClientRect();
        if (rect.top > 0 && rect.top < 300) {
          const location = window.location.toString().split("#")[0];
          history.replaceState(null, null, location + "#" + ha.id);
          links.forEach((element) => {
            element.classList.remove("_active");
          });
          const link = document.querySelector(`[data-menuanchor='${ha.id}']`);
          if (link) {
            link.classList.add("_active");
          }

          if (ha.id === "create") {
            console.log(!videoPlay);
            if (!videoPlay) {
              if (window.innerWidth >= 1024) {
                setTimeout(() => {
                  document.querySelector(`#video-desktop-0`).play();
                }, 3000);
              } else {
                // vid.autoplay = 'autoplay';
                // vid.addEventListener('loadeddata', function end(e) {
	              //   console.log('onloadeddata');
                //   setVideoBgColor(vid, wrapper);
                //   vid.play();
                //   vid.removeEventListener("loadeddata", end);
                // });
                // setVideoBgColor(vid, wrapper);
                setTimeout(() => {
                  document.querySelector(`#video-0`).play();
                  $this.videoFinish = false;
                
                  vid.addEventListener("ended", function end(e) {
                    console.log(e);
                    $this.videoFinish = true;
                    videoPlay = true;
                    console.log($this.videoFinish);
                    vid.removeEventListener("ended", end);
                  });
                }, 2000);
              }
            }
          }
          document.getElementById(ha.id).classList.add("active");
        }
      });
      if (window.scrollY === 0 && this.$route.name === "Home") {
        const location = window.location.toString().split("#")[0];
        links.forEach((element) => {
          element.classList.remove("_active");
        });
        history.replaceState(null, null, location + "#home");
        document
          .querySelector(`[data-menuanchor='home']`)
          .classList.add("_active");
      }
    });
    // }
  },
  created() {
    this.onResize();
  },
  components: {
    Hero,
    Steps,
    PrizesBlock,
    AppEdadilPromo,
    AppWhereToBuy,
    // AppFaq,
    // AppWinners,
    AppTest,
  },
};
</script>

<style lang="scss"></style>
