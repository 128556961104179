var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"email-form"},[_c('InputText',{staticClass:"email-form__input",class:{
      error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
    },attrs:{"placeholder":"E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
        ? 'Обязательное поле'
        : !_vm.$v.email.email && _vm.$v.email.$error
        ? 'Некорректный e-mail'
        : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('button',{staticClass:"btn btn--send email-form__button",attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }