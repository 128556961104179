<template lang="pug">
section.edadil-promo.section#edadil
  ul.edadil-promo__list
    li.edadil-promo__step(
      v-for="(item, index) in steps" :key="index"
    )
      .edadil-promo__step-title 
        strong.gradient-text {{ index + 1 }}
        span(v-html="item.name")
      img.edadil-promo__step-img(
        v-if="item.image"
        :src="require(`../assets/images/steps/${item.image}`)"
      )
      .edadil-promo-buttons(v-if="item.button")
        .edadil-promo-buttons__button#button
        #scanner
</template>

<script>
export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    steps: [
      {
        name: "Покупайте <br>«Коркунов»",
        image: "step-1.png",
      },
      {
        name: "СКАНИРУЙте<br>ЧЕК В «Едадил»",
        button: true,
      },
      {
        name: "ВЫигрывайте",
        image: "step-3.png",
      },
    ],
  }),
  mounted() {
  }
};
</script>

<style lang="scss">
$time: 0.4s;
.edadil-promo {
  position: relative;
  width: 100%;
  height: rem(450px);
  box-shadow: rem(8px) rem(8px) rem(50px) rgba(0, 0, 0, 0.3);
  z-index: 1;
  &__list {
    @include size(100%);
    display: flex;
    align-items: stretch;
  }
  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 34%;
    padding-top: rem(60px);
    padding-right: rem(75px);
    color: #fff;
    margin-right: rem(-75px);
    background: url("../assets/images/steps/step-bg-1.svg") no-repeat center
      right;
    background-size: auto 100%;
    transition: 1s;
    transition-delay: $time;
    transition-timing-function: ease-in-out;
    z-index: 1;
    &:nth-of-type(2) {
      transition-delay: $time * 3;
      z-index: 0;
    }
    &:nth-of-type(3) {
      transition-delay: $time * 6;
      background-position: center left;
      z-index: -1;
    }
    .active & {
      background-image: url("../assets/images/steps/step-bg-2.svg");
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: rem(32px);
      line-height: rem(43px);
      span {
        flex-grow: 0;
        flex-shrink: 0;
      }
      strong {
        margin-right: rem(20px);
        font-size: rem(96px);
        line-height: 1;
      }
    }
    &-img {
      max-width: 60%;
      margin: auto;
    }
  }
  &-buttons {
    display: flex;
    align-items: center;
    margin: auto;
    &__button {
      width: rem(276px);
      height: rem(74px);
      .info-button {
        &__button {
          border-radius: 0;
        }
        &__wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &__image-wrapper {
          flex-shrink: 0;
        }
        &__info {
          flex: auto;
        }
        &__title {
          color: #47AB49;
          font-family: "Avenir-Bold";
        }
      }
    }
    .scan-button {
      &__wrapper {
        @include size(rem(74px));
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      &__button {
        border-radius: 0;
      }
    }
  }
}
</style>
