<template lang="pug">
Modal(name="select_prize" size="md" @closeModal="closeModal")
  template(v-slot:content)
    .modal__title.gradient-text Выберите приз
    .modal-partners 
      .modal-partners__item(@click="active = 1" :class="{ active: active === 1 }")
        .modal-partners__item-image
          img(src="../../assets/images/ozon.png", alt="")
        span купон <br> OZOn
      .modal-partners__item(@click="active = 2" :class="{ active: active === 2 }")
        .modal-partners__item-image
          img(src="../../assets/images/sber.png", alt="")
        span купон<br>СБЕРМАРКЕТ
    button.btn.btn--primary подтвердить
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: () => ({
    active: null,
  }),
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>

<style lang="scss">
.modal-select_prize {
  padding-top: rem(75px);
  .modal__title {
    margin-bottom: rem(27px);
    font-size: rem(36px);
    line-height: 1.2;
    font-family: "Demi";
  }
  .btn {
    display: block;
    width: 100%;
    margin: auto;
    height: rem(42px);
    line-height: rem(42px);
    font-size: rem(16px);
  }
  @media (min-width: $md) {
    .modal__title {
      margin-bottom: rem(36px);
    }
    .btn {
      width: auto;
    }
  }
}
.modal-partners {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &__item {
    min-width: rem(140px);
    margin: 0 0 rem(32px);
    cursor: pointer;
    &-image {
      position: relative;
      display: flex;
      @include size(rem(86px));
      margin: auto;
      margin-bottom: rem(10px);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        border-radius: 50%;
        border: rem(3px) solid #c29b44;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: 0.4s;
      }
    }
    &:hover,
    &.active {
      .modal-partners__item-image {
        &::before {
          opacity: 1;
        }
      }
    }
    img {
      display: block;
      margin: auto;
      @include size(rem(68px));
    }
    span {
      width: 100%;
      display: block;
      color: #cda859;
      text-transform: uppercase;
      text-align: center;
      font-size: rem(18px);
      line-height: 1.1;
    }
  }
  @media (min-width: $md) {
  }
}
</style>
