<template lang="pug">
.test-slider
  .test-slider__total.hidden-xs 
    span.gradient-text(v-if="!result") {{ current + 1 }} из {{ questions.length }}
  VueSlickCarousel.test-slider__slider(v-if="!result" v-bind="settings" ref="carousel")
    .test-slider__slide(v-for="(item, index) in questions" :key="index")
      .test-slider__slide-title(v-html="item.text")
      .test-slider__slide-options
        button.test-slider__slide-option(
          v-for="(opt, i) in item.options" 
          :key="i" 
          v-html="opt"
          @click="selectAnswer(i)"
          :class="{ active: answer === i + 1 || answers[index] === i + 1 }"
        )
      .test-slider__slide-buttons
        button.test-slider__slide-btn.btn._prev(
          :class="{ show: current > 0 }"
          @click="showPrev()"
        ) 
          img(src="../../assets/images/icons/arrow-right.svg")
          span НАЗАД
        button.test-slider__slide-btn.btn.btn--secondary._next(
          :class="{ show: answer != null}"
          @click="showNext()"
        ) 
          span Дальше
          img(src="../../assets/images/icons/arrow-right.svg")
  .test-slider__result(v-else)
    img.test-slider__result-image(:src="require(`../../assets/images/writers/writer-${result}.png`)", alt="")
    .test-slider__result-buttons 
      .test-slider__result-btn.btn.btn--secondary( @click="downloadWithAxios(`${publicPath}writers/postcard-${result}.jpg`, `postcard-${result}.jpg`)") 
        span СКАЧАТь Результат
        img(src="../../assets/images/icons/download.svg")
      button.test-slider__result-btn.btn.btn--secondary(@click="$modal.show('select_prize')")
        span Получить приз
        img(src="../../assets/images/icons/present.svg")
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import axios from "axios";
export default {
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    current: 0,
    answer: null,
    result: null,
    questions: [
      {
        text: "Представьте, что вы&nbsp;впервые решили написать произведение. О&nbsp;чем оно будет?",
        options: [
          "Про грустного кота.",
          "Это был бы юмористический рассказ.",
          "Думаю, я&nbsp;бы посвятил(а) его красоте природы.",
          "Это будет произведение о&nbsp;высокой любви.",
        ],
      },
      {
        text: "За что вам нравится весна?",
        options: [
          "Пожалуй, что за&nbsp;все! <br>Принимаю и&nbsp;с&nbsp;радостью встречаю ее&nbsp;каждый год.",
          "Это счастливое <br>время года для меня! <br>Все такое радостное и&nbsp;приветливое.",
          "Обыкновенно весна смывает с&nbsp;меня всю грусть.",
          "Больше всего ценю ее за&nbsp;просыпающиеся краски.",
        ],
      },
      {
        text: "А&nbsp;что весной вас вдохновляет?",
        options: [
          "Конечно, природа. <br>Ощущение пробуждающейся жизни во&nbsp;всем...",
          "Народно-поэтические образы... <br>Чувствую себя художником пейзажистом!",
          "Люди&nbsp;&mdash; мое вдохновение. <br>А&nbsp;весна&nbsp;&mdash; время новых знакомств!",
          "Для меня весна&nbsp;&mdash; время новых открытий. Я&nbsp;нахожу вдохновение во&nbsp;всем!",
        ],
      },
      {
        text: "Какие строки наиболее ярко выражают ваши мысли о&nbsp;любви?",
        options: [
          "Как день, светла, <br>но&nbsp;непонятна...",
          "Живите так, <br>Как вас ведет звезда...",
          "Какое это огромное счастье&nbsp;&mdash; <br>любить и&nbsp;быть любимым.",
          "Любовь&nbsp;&mdash; это полное слияние умов, мыслей, душ, интересов, а&nbsp;не&nbsp;одних только тел.",
        ],
      },
      {
        text: "Если&nbsp;бы вы&nbsp;написали стихотворение своей (-ему) возлюбленной (-ому), с&nbsp;чего&nbsp;бы оно начиналось?",
        options: [
          "Мы&nbsp;были вместе, помню&nbsp;я... <br>Ночь волновалась, скрипка пела...",
          "Ты&nbsp;прохладой меня не&nbsp;мучай <br>И&nbsp;не&nbsp;спрашивай, сколько мне лет...",
          "Носилась ты&nbsp;в&nbsp;моих мечтах, <br>Неся с&nbsp;собой любви удары.",
          "Развлеку тебя забавной сказкой, <br>Песенку веселую спою.",
        ],
      },
    ],
    settings: {
      infinity: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "80px",
          },
        },
      ],
    },
  }),
  computed: {
    answers() {
      let arr = [];
      for (let index = 0; index < this.questions.length; index++) {
        arr.push(null);
      }
      return arr;
    },
  },
  methods: {
    showPrev() {
      this.current--;
      this.answer = null;
      this.$refs.carousel.prev();
    },
    selectAnswer(i) {
      this.answer = i + 1;
      this.answers[this.current] = this.answer;
    },
    showNext() {
      if (this.questions.length > this.current + 1) {
        this.current++;
        this.$refs.carousel.goTo(this.current);
        this.answer = null;
      } else {
        this.getResult();
      }
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
    getResult() {
      const countItems = {};
      for (const item of this.answers) {
        countItems[item] = countItems[item] ? countItems[item] + 1 : 1;
      }
      const result = Object.keys(countItems).filter(
        (item) => countItems[item] > 1
      );
      if (result.length > 1) {
        console.log(this.getRandom(result));
        this.getRandom(result);
      } else {
        console.log(result);
        return (this.result = result);
      }
    },
    getRandom(list) {
      return (this.result = list[Math.floor(Math.random() * list.length)]);
    },
  },
};
</script>

<style lang="scss">
.slick-current {
  z-index: 100 !important;
}
.test-slider {
  &__total {
    position: relative;
    width: 100%;
    margin-bottom: rem(50px);
    text-align: center;
    font-size: rem(16px);
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(100%, rem(75px));
      transform: translate(-50%, -50%);
      background: url("../../assets/images/ribbons.png") no-repeat center;
      background-size: auto 100%;
      z-index: -1;
    }
  }
  &__slider {
    padding: 0 rem(30px);
  }
  &__slide {
    &-title {
      max-width: 100%;
      min-height: rem(105px);
      margin: auto;
      margin-bottom: rem(37px);
      font-size: rem(24px);
      line-height: rem(26px);
      color: #b79853;
      text-align: left;
    }
    &-options {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 0 rem(20px);
    }
    &-option {
      position: relative;
      flex: 0 0 100%;
      height: rem(93px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      padding: rem(16px);
      margin: 0 0 rem(10px);
      color: #9e0d53;
      font-size: rem(17px);
      line-height: 1;
      font-family: "Avenir-Light";
      font-weight: 600;
      &::after {
        content: "";
        display: block;
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(204, 8, 90, 0.1);
        z-index: -2;
      }
      &::before {
        content: "";
        display: block;
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          258.95deg,
          #e2b758 17.32%,
          #fff5e0 50.82%,
          #e2b758 72.69%
        );
        transition: 0.4s;
        opacity: 0;
        z-index: -1;
      }
      &.active {
        &::before {
          opacity: 1;
        }
      }
    }
    &-buttons {
      display: flex;
      justify-content: center;
    }
    &-btn.btn {
      display: flex;
      align-items: center;
      width: rem(214px);
      min-width: auto;
      height: rem(50px);
      margin: 0 rem(7px);
      padding: 0 rem(15px);
      line-height: rem(45px);
      font-size: rem(16px);
      font-family: "Demi";
      opacity: 0;
      pointer-events: none;
      &:hover {
        box-shadow: 0px rem(4px) rem(16px) rgba(0, 0, 0, 0.25);
      }
      &._next {
        background: #cc085a;
        border: none;
      }
      &._prev {
        border: rem(3px) solid #c29b44;
        img {
          display: block;
          transform: rotate(180deg);
        }
      }
      &.show {
        opacity: 1;
        pointer-events: all;
      }
      span {
        margin: auto;
        color: #c29b44;
        background: none;
      }
    }
  }
  &__result {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: rem(0px) rem(23px) rem(32px);
    &-image {
      width: 100%;
      margin-bottom: rem(56px);
    }
    &-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &-btn.btn {
      display: flex;
      align-items: center;
      margin-bottom: rem(10px);
      padding: 0 rem(20px);
      height: rem(66px);
      font-size: rem(18px);
      color: #c9a149;
      background: #cc085a;
      border-color: #cc085a;
      span {
        display: block;
        margin: 0 auto;
      }
      * {
        flex-shrink: 0;
      }
      img {
        width: rem(25px);
      }
    }
  }
  @media (min-width: $md) {
    &__total {
      margin-bottom: rem(62px);
      font-size: rem(20px);
      &::before {
        @include size(100%, rem(146px));
      }
    }
    &__slider {
      padding: 0 rem(68px);
    }
    &__slide {
      &-title {
        max-width: 90%;
        text-align: center;
        font-size: rem(32px);
        line-height: rem(35px);
      }
      &-options {
        width: calc(100% + rem(14px));
        margin: 0 rem(-7px) rem(26px);
      }
      &-option {
        height: rem(105px);
        text-align: center;
        justify-content: center;
        flex: 0 0 calc(50% - #{rem(14px)});
        margin: 0 rem(7px) rem(14px);
        font-size: rem(20px);
      }
      &-btn.btn {
        padding: 0 rem(20px);
        font-size: rem(18px);
      }
    }
    &__result {
      flex-direction: row;
      padding: rem(80px) rem(45px) rem(32px);
      &-image {
        width: 48%;
        margin-right: rem(32px);
      }
    }
  }
}
</style>
