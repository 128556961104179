<template lang="pug">
section.test-section.section#test
  .container
    .section__title(data-show) Пройдите тест и&nbsp;узнайте, <br>с&nbsp;каким писателем <br>вы&nbsp;близки по&nbsp;духу
    button.test-section__btn.btn.btn--secondary.hidden-xs(data-show @click="$modal.show('test_popup')")
      span.gradient-text Пройти тест
    img.test-section__arrow(src="../assets/images/icons/arrow-down.svg" data-show)
    p.test-section__text(data-show) Получите купоны <br>на&nbsp;покупку&nbsp;в
    .test-section__partners(data-show)
      img.test-section__partner(src="../assets/images/ozon.png" srcset="../assets/images/ozon@2x.png 2x", alt="")
      img.test-section__partner(src="../assets/images/sber.png" srcset="../assets/images/sber@2x.png 2x", alt="")
    button.test-section__btn.btn.btn--secondary.visible-xs(data-show @click="$modal.show('test_popup')")
      span.gradient-text Пройти тест
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.test-section {
  padding: rem(80px) rem(10px) rem(60px);
  background: #f8decb;
  .section__title {
    color: #b79955;
    font-size: rem(26px);
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__btn.btn {
    height: rem(66px);
    font-size: rem(18px);
  }
  &__arrow {
    width: rem(21px);
    margin-bottom: rem(33px);
  }
  &__text {
    margin-bottom: rem(24px);
    font-size: rem(19px);
    color: #9e0d53;
    font-family: "Avenir-Light";
    font-weight: 600;
    text-align: center;
    letter-spacing: rem(3px);
    line-height: 1.1;
  }
  &__partners {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(57px);
  }
  &__partner {
    @include size(rem(68px));
    margin: 0 rem(20px);
  }
  @media (min-width: $md) {
    padding: rem(92px) 0 rem(115px);
    .section__title {
      margin-bottom: rem(46px);
      font-size: rem(36px);
      line-height: rem(40px);
    }
    &__btn.btn {
      height: rem(73px);
      margin-bottom: rem(50px);
      font-size: rem(24px);
    }
    &__arrow {
      width: rem(51px);
      margin-bottom: rem(47px);
    }
    &__text {
      margin-bottom: rem(46px);
      font-size: rem(32px);
    }
    &__partners {
      margin-bottom: 0;
    }
    &__partner {
      @include size(rem(105px));
      margin: 0 rem(32px);
    }
  }
}
</style>
